import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store/index.js"
import Home from '../views/Home.vue'
import Reg from '../views/accounts/Reg'
import Login from '../views/accounts/Login'
import Spec from '../views/hundbk/Spec'
import Units from '../views/hundbk/edizm'
import GroopsGoods from '../views/hundbk/GroupGoods'
import Goods from '../views/hundbk/Goods'
import Arrival from '../views/work/Arrival'
import Provider from '../views/hundbk/Provider'
import Consumption from '../views/work/Consumption'
import Bid from '../views/work/Bid'
import ReturnGoods from '../views/work/ReturnGoods'
import Cause from '../views/hundbk/Cause'
import Balance from '../views/report/Balance'
import BalanceReverse from '../views/report/Reverse'
import TimeSheet from '../views/work/master/TimeSheet.vue'
import ProductIngener from '../views/work/ingener/Product.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/reg',
    name: 'reg',
    component: Reg,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/handbk/spec',
    name: 'Spec',
    component: Spec,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/handbk/units',
    name: 'Units',
    component: Units,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/handbk/cause',
    name: 'Cause',
    component: Cause,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/handbk/GroupsGoods',
    name: 'GroopsGoods',
    component: GroopsGoods,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/handbk/goods',
    name: 'goods',
    component: Goods,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/handbk/provider',
    name: 'provider',
    component: Provider,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/work/arrival',
    name: 'arrival',
    component: Arrival,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/work/bid',
    name: 'bid',
    component: Bid,
    meta: {
      requiresAuth: true
    }
  },
    {
    path: '/work/consumption',
    name: 'arrival',
    component: Consumption,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/work/return',
    name: 'ReturnGoods',
    component: ReturnGoods,
    meta: {
      requiresAuth: true
    }
  },
{
    path: '/work/timesheet',
    name: 'timesheet',
    component: TimeSheet,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/report/balance',
    name: 'balance',
    component: Balance,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/report/reverse',
    name: 'Reverse',
    component: BalanceReverse,
    meta: {
      requiresAuth: true
    }
  },
   {
    path: '/ingener/product',
    name: 'ProductIngener',
    component: ProductIngener,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.getAuthClient) {
      localStorage.setItem("ecopack-user-link", to.path);
      //console.log(to.path);
      next()
      return
    }
    next('/login') 
  } else {
    next() 
  }
})
export default router
