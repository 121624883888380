import Vue from 'vue'
import Vuex from 'vuex'
//import Axios from '../../plugins/axios'
import Axios from 'axios';

Vue.use(Vuex)

export default ({
    state: {
        listBalance: [],
        listBalanceReverse: [],
    },
    getters: {
        listBalance(state) {
            return state.listBalance
        },
        listBalanceReverse(state) {
            return state.listBalanceReverse
        },

    },


    mutations: {
        updatelistBalance(state, payload) {
            state.listBalance = payload;

        },
        updatelistBalanceReverse(state, payload) {
            state.listBalanceReverse = payload;

        },

    },


    actions: {
        newlistArrival1({ commit }, payload) {
            commit("onLoad", true);
            Axios.post("/api/v1/work/set/arrival", {
                zn: payload
            })
                .then((res) => {
                    console.log(res);
                    commit("onLoad", false);
                    commit("updatelistArrival", res.data.message);

                })
                .catch(() => {
                    console.log("error");
                });
        },

        getlistBalance({ commit }) {
            commit("onLoad", true);
            Axios.post("/api/v1/report/get/balance")
                .then((res) => {
                    console.log(res);
                    commit("onLoad", false);
                    commit("updatelistBalance", res.data.message);

                })
                .catch(() => {
                    console.log("error");
                });
        },
        getlistBalanceReverse({ commit }) {
            commit("onLoad", true);
            Axios.post("/api/v1/report/get/balanceReverse")
                .then((res) => {
                    console.log(res);
                    commit("onLoad", false);
                    commit("updatelistBalanceReverse", res.data.message);

                })
                .catch(() => {
                    console.log("error");
                });
        },

        
    },
})