<template>
    <v-container grid-list-xs>
        

            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    Товары
                </v-card-title>

                <v-card-text>
                    <v-form ref="form" lazy-validation>
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-autocomplete v-model="dt.ggoods" :items="tpgoods" dense chips small-chips
                                    label="Тип товара"></v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="12">
                                <v-text-field v-model="dt.name" label="Название" required></v-text-field>
                            </v-col>
                            <v-col cols="12" md="9">
                                <v-autocomplete v-model="dt.ed" :items="edizm" dense chips small-chips
                                    label="Ед. измерения"></v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-switch v-model="dt.ras" label="многоразовый"></v-switch>
                            </v-col>
                            <v-col cols="12" md="3" style="display: none;">
                                <v-text-field v-model="dt.num" label="учетный номер" required></v-text-field>
                                <v-text-field v-model="dt.col" label="минимальное кол-во для оповещения." required></v-text-field>
                            </v-col>
                        </v-row>

                    </v-form>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn color="success" class="mr-4" @click="SaveUser">
                        Сохранить
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="error" class="mr-4" @click="dialog = false">
                        Отменить
                    </v-btn>
                </v-card-actions>
            </v-card>
       
        
    </v-container>
</template>
  
<script>
// @ is an alias to /src


export default {
    name: 'AddGoods',
    data: () => ({
        dt: {
            id: 0,
            name: '',
            ggoods: '',
            ed: '',
            ras: false,
            num: 0,
            col: 0.0,
        },
       
    }),

    components: {

    },
    computed: {
        desserts() {
            return this.$store.getters.listGoods;
        },
        tpgoods() {
            return this.$store.getters.listTypeGoodsSelect;
        },
        edizm() {
            return this.$store.getters.listUnitsSelect;
        }

    },
    methods: {
       
        SaveUser() {
            
            this.$store.dispatch("newlistGoods", this.dt);
            this.dialog = false;
        },
        
    },
    mounted() {
        this.$store.dispatch("getlistTypeGoods");
        this.$store.dispatch("getlistUnits");
    }
}
</script>
  