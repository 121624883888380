<template>
    <v-container grid-list-xs>


        <v-row>
            <v-col>
                <v-card>
                    <v-card-title class="text-h5 grey lighten-2">
                        Вход
                    </v-card-title>

                    <v-card-text>
                        <v-form ref="form" lazy-validation>
                            <v-text-field v-model="dt.login" label="Логин" required></v-text-field>
                            <v-text-field type="password" v-model="dt.ps" label="Пароль" required></v-text-field>
                            <v-text-field type="password" v-model="dt.old" label="email" required
                                style="display: none;"></v-text-field>
                        </v-form>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="success" class="mr-4" @click="SaveData">
                            Войти
                        </v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>
  
<script>
// @ is an alias to /src


export default {
    name: 'Home',
    data: () => ({
        dt: {
            login: '',
            ps: '',
            old: '',
        },


    }),

    components: {

    },
    computed: {
        isAuthUser() {
      return this.$store.getters.getAuthClient;
    },
    },
    methods: {

        SaveData() {
            if (this.dt.old == '') {
                let t = {
                    'login': this.dt.login,
                    'passwd': this.dt.ps,
                }
                this.$store.dispatch("AuthPerson", t).then(rs=>{
                    console.log(rs);
                    if(rs==true){
                        
                        this.$router.push('/')
                    } else {
                        alert('Не верный логин или пароль!');
                    }
                });
            } else {
                alert('Не верный логин или пароль!')
            }

        },

    },
    mounted() {
        if(this.isAuthUser){
            this.$router.push('/')
        }
        this.$store.dispatch("getlistUnits");
    }
}
</script>
  