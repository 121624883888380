<template>
    <v-container grid-list-xs>
        <v-dialog v-model="dialog" width="900">

            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    Работа с товаром
                </v-card-title>

                <v-card-text>
                    <v-form ref="form" lazy-validation>
                        <v-row>
                             <v-col cols="12">
                                <v-text-field type="date" v-model="dt.date" label="дата" required></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-text-field v-model="dt.count_bath" label="Кол-во ван" required></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-text-field v-model="dt.sum_rise" label="Суммарное кол-во подьемов" required></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-text-field v-model="dt.volume_pro" label="объем материалов" required></v-text-field>
                            </v-col>
                            </v-row>
                            <v-row v-for="(n,i) in dt.product" :key="i">
                            <v-col cols="12">
                                <v-text-field v-model="n.name" label="Наименование продукции" required></v-text-field>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-text-field v-model="n.count" label="кол-во" required></v-text-field>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-text-field v-model="n.weight" label="тонаж в кг" required></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-autocomplete v-model="n.status" :items="['отгружено', 'сортировка', 'произведено']" dense chips small-chips
                                    label="действие"></v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-btn color="success" class="mr-4" @click="newProduct(n.btn, i)">{{ n.btn }}</v-btn>
                            </v-col>
                        </v-row>
                        

                    </v-form>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn color="success" class="mr-4" @click="SaveData">
                        Сохранить
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="error" class="mr-4" @click="dialog = false">
                        Отменить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-row>
            <v-col cols="12" md="6">
                <h1>Продукция</h1>
            </v-col>
            <v-col cols="12" md="6">
                <v-btn class="btn" @click="newData">Добавить</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>
                        Список продукции
                        <v-spacer></v-spacer>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Поиск" single-line
                            hide-details></v-text-field>
                    </v-card-title>
                    <v-data-table :headers="headers" :items="desserts.tt" :search="search"></v-data-table>
                </v-card>
            </v-col>
            <v-col>
                <v-card>
                    <v-card-title>
                        Список ванн
                        <v-spacer></v-spacer>
                        <v-text-field v-model="search1" append-icon="mdi-magnify" label="Поиск" single-line
                            hide-details></v-text-field>
                    </v-card-title>
                    <v-data-table :headers="headers_goods" :items="desserts.dd" :search="search"></v-data-table>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>
  
<script>
// @ is an alias to /src


export default {
    name: 'Arrival',
    data: () => ({
        dt: {
            idb: 0,
            date: '',
            count_bath: 0,
            sum_rise: 0,
            volume_pro: 0,
            product: [
                {
                    name: '',
                    count: 0,
                    weight: 0,
                    status: 'произведено',
                    btn: '+'
                }
            ],
        },
        listgg: {
            goods: '',
            col: 0,
            cause: 'общие расходы',
        },
        dialog: false,
        search: '',
        search1: '',
        headers: [
            {
                text: 'id',
                align: 'start',
                value: 'id',
            },
            { text: 'продукция', value: 'name_product' },
            { text: 'кол-во', value: 'count_prod' },
            { text: 'вес', value: 'weight' },
            { text: 'дата', value: 'date_create' },
            { text: 'действие', value: 'status' },


        ],
        headers_goods: [
            { text: 'кол-во ван', value: 'count_bath' },
            { text: 'объем', value: 'volume_tree' },
            { text: 'кол-во поъемов', value: 'count_rise' },
            { text: 'дата', value: 'date_create' },
        ]
    }),


    computed: {
        desserts() {
            return this.$store.getters.listProductIngener;
        },
        

    },
    methods: {
        newData() {
            this.dialog = true;
        },
        newProduct(t, i) {
            if(t=='+'){
                this.dt.product[this.dt.product.length-1].btn = '-';
                this.dt.product.push( {
                        name: '',
                        count: 0,
                        weight: 0,
                        status: 'произведено',
                        btn: '+'
                    });

            }
            else {
                let result = confirm('Вы уверены что хотите удалить?');
                if (result) {
                
                        this.dt.product.splice(i, 1);
                       
                }
                
            
            }
        },
        
        
        SaveData() {
            console.log(this.dt);
            this.$store.dispatch("newProductUser", this.dt);
            this.dialog = false;
        },
        
    },
    mounted() {
        this.$store.dispatch("getProductUser");
    }
}
</script>
  