<template>
    <v-container grid-list-xs>
        <v-dialog v-model="dialog" width="900">

            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    Расход
                </v-card-title>

                <v-card-text>
                    <v-form ref="form" lazy-validation>
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field type="date" v-model="dt.date" label="дата" required></v-text-field>
                            </v-col>
                            <v-col cols="12" md="12">
                                <v-autocomplete v-model="dt.person" :items="person" dense chips small-chips
                                    label="Работник"></v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="12">
                                <v-autocomplete v-model="dt.spec" :items="spec" dense chips small-chips
                                    label="специальность"></v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-autocomplete v-model="goods.name" :items="tpgoods" chips small-chips
                                    label="Товар"></v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-text-field v-model="goods.col" type="number" label="Кол-во" required></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-autocomplete v-model="goods.cause" :items="cause" chips small-chips
                                    label="На какие нужды"></v-autocomplete>
                                    <v-text-field v-if="goods.cause=='новая'" v-model="goods.dop" label="Новая причина" required></v-text-field>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-btn @click="addData">+</v-btn>
                            </v-col>
                            <v-col cols="12">
                                <v-data-table :headers="headers_goods" :items="dt.list">
                                    <template v-slot:item.act="{ item }">
                                        <v-btn @click="delData(item)" :key="item.name">-</v-btn>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>


                    </v-form>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn color="success" class="mr-4" @click="SaveData">
                        Сохранить
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="error" class="mr-4" @click="delDt">
                        Удалить
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="warning" class="mr-4" @click="dialog = false">
                        Отменить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-row>
            <v-col cols="12" md="6">
                <h1>Расход товаров</h1>
            </v-col>
            <v-col cols="12" md="6">
                <v-btn class="btn" @click="newData">Добавить</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>
                        Расход товаров
                        <v-spacer></v-spacer>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Поиск" single-line
                            hide-details></v-text-field>
                    </v-card-title>
                    <v-data-table :headers="headers" :items="desserts" :search="search"
                        @click:row="openData"></v-data-table>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
// @ is an alias to /src


export default {
    name: 'GroopsGoods',
    data: () => ({
        dt: {
            id: 0,
            date: new Date().toISOString().substr(0, 10),
            person: '',
            spec: '',
            list: [],
        },
        goods: {
            name: '',
            col: 0,
            cause: 'общие расходы',
            dop: '',
        },
        dialog: false,
        search: '',
        headers: [
            {
                text: 'id',
                align: 'start',
                value: 'id',
            },
            { text: 'дата', value: 'dtv' },
            { text: 'ФИО', value: 'pers' },
            { text: 'специальность', value: 'nameSpec' },
            { text: 'данные', value: 'ccc' },
        ],
        headers_goods: [
            { text: 'товар', value: 'name' },
            { text: 'кол-во', value: 'col' },
            { text: 'куда', value: 'cause' },
            { text: 'действие', value: 'act' },
        ]

    }),

    components: {

    },
    computed: {
        desserts() {
            return this.$store.getters.listConsumption;
        },
        tpgoods() {
            return this.$store.getters.listGoodsSelect;
        },
        cause() {
            return this.$store.getters.listCauseSelect;
        },
        person() {
            return this.$store.getters.listUsersSelect;
        },
        spec() {
            return this.$store.getters.listSpecSelect;
        }

    },
    methods: {
        newData() {
            this.dt.id = 0;
            this.dt.date = new Date().toISOString().substr(0, 10);
            this.goods.goods = '';
            this.dt.person = '';
            this.dt.spec = '';
            this.dt.list = [];
            this.goods.col = 0;
            this.goods.cause = 'общие расходы';
            this.dialog = true;
        },
        addData() {
            this.dt.list.push(JSON.parse(JSON.stringify(this.goods)));
            this.goods.name = '';
            this.goods.col = 0;
            this.goods.cause = 'общие расходы';
        },
        delData(e) {
            let result = confirm('Вы уверены что хотите удалить?');
            if (result) {
                for (let i = 0; i < this.dt.list.length; i++) {
                    if (this.dt.list[i].name == e.name) {
                        this.dt.list.splice(i, 1);
                        break;
                    }
                }
                console.log(e.goods);
            }
        },
        delDt() {
            let result = confirm('Вы уверены что хотите удалить?');
            if (result) {
            this.$store.dispatch("DeletelistConsumption", this.dt.id);
            this.dialog = false;
            }
        },
        SaveData() {
            this.$store.dispatch("newlistConsumption", this.dt);
            this.dialog = false;
        },
        openData(e) {
            this.dt.id = e.id;
            this.dt.date = e.dateCreate;
            this.dt.person = e.pers;
            this.dt.spec = e.nameSpec;
            this.dt.list = e.list;
            this.dialog = true;
        }
    },
    mounted() {
        this.$store.dispatch("getlistConsumption");
        this.$store.dispatch("getlistGoods");
        this.$store.dispatch("getlistCause");
        this.$store.dispatch("getUserList");
        this.$store.dispatch("getlistSpec");
    }
}
</script>