<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <div class="align-center">
        EcoPack
      </div>
      <v-spacer></v-spacer>
      <div v-if="auth" class="align-center">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on" style="margin: 5px;">
              Справочники
            </v-btn>
          </template>
          <v-list>
            <v-list-item key="1" to="/reg">
              <v-list-item-title>пользователи</v-list-item-title>
            </v-list-item>

            <v-list-item key="2" to="/handbk/spec">
              <v-list-item-title>должности</v-list-item-title>
            </v-list-item>

            <v-list-item key="3" to="/handbk/units">
              <v-list-item-title>ед. изм</v-list-item-title>
            </v-list-item>
            <v-list-item key="4" to="/handbk/cause">
              <v-list-item-title>причины</v-list-item-title>
            </v-list-item>
            <v-list-item key="5" to="/handbk/GroupsGoods">
              <v-list-item-title>группы товаров</v-list-item-title>
            </v-list-item>
            <v-list-item key="6" to="/handbk/goods">
              <v-list-item-title>товары</v-list-item-title>
            </v-list-item>
            <v-list-item key="6" to="/handbk/provider">
              <v-list-item-title>поставщики</v-list-item-title>
            </v-list-item>
          </v-list>

        </v-menu>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on" style="margin: 5px;">
              Рабочий процесс
            </v-btn>
          </template>
          <v-list>
            <v-list-item key="7" to="/work/arrival">
              <v-list-item-title>приход</v-list-item-title>
            </v-list-item>

            <v-list-item key="2" to="/work/consumption">
              <v-list-item-title>расход</v-list-item-title>
            </v-list-item>

            <v-list-item key="3" to="/work/bid">
              <v-list-item-title>заявки</v-list-item-title>
            </v-list-item>
            <v-list-item key="4" to="/work/timesheet">
              <v-list-item-title>Табель учета рабочего времени</v-list-item-title>
            </v-list-item>
          </v-list>

        </v-menu>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on" style="margin: 5px;">
              Отчеты
            </v-btn>
          </template>
          <v-list>
            <v-list-item key="7" to="/report/balance">
              <v-list-item-title>Остатки</v-list-item-title>
            </v-list-item>
            <v-list-item key="7" to="/report/reverse">
              <v-list-item-title>Оборотно-сальдовая ведомость</v-list-item-title>
            </v-list-item>

          </v-list>

        </v-menu>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on" style="margin: 5px;">
              Инженер
            </v-btn>
          </template>
          <v-list>
            <v-list-item key="7" to="/ingener/product">
              <v-list-item-title>Товары</v-list-item-title>
            </v-list-item>
            

          </v-list>

        </v-menu>
        <!-- <v-btn to="/work/return" text>
          <span class="mr-2">возврат</span>
        </v-btn> -->

      </div>
      <v-spacer></v-spacer>

      <v-btn v-if="!auth" to="/login" text>
        <span class="mr-2">Войти</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
      <v-btn v-else @click='exitUser' text>
        <span class="mr-2">Выйти</span>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
  computed: {
    auth() {
      return this.$store.getters.getAuthClient;
    },
  },
  methods: {
    exitUser() {
      this.$store.dispatch("getExitClient")
      this.$router.push('/')
    }
  }
};
</script>
