import Vue from 'vue'
import Vuex from 'vuex'
//import Axios from '../../plugins/axios'
import Axios from 'axios';

Vue.use(Vuex)

export default ({
    state: {
        listSpec: [],
        listSpecSelect: [],
        listUnits: [],
        listUnitsSelect: [],
        listTypeGoods: [],
        listTypeGoodsSelect: [],
        listGoods: [],
        listGoodsSelect: [],
        listProvider: [],
        listProviderSelect: [],
        listCause: [],
        listCauseSelect: [],
        listChageWork:[],
    },
    getters: {
        listSpecs(state) {
            return state.listSpec
        },
        listSpecSelect(state) {
            return state.listSpecSelect
        },
        listUnits(state) {
            return state.listUnits
        },
        listUnitsSelect(state) {
            return state.listUnitsSelect
        },
        listTypeGoods(state) {
            return state.listTypeGoods
        },
        listTypeGoodsSelect(state) {
            return state.listTypeGoodsSelect
        },
        listGoods(state) {
            return state.listGoods
        },
        listGoodsSelect(state) {
            return state.listGoodsSelect
        },
        listProvider(state) {
            return state.listProvider
        },
        listProviderSelect(state) {
            return state.listProviderSelect;
        },
        listCause(state) {
            return state.listCause;
        },
        listCauseSelect(state) {
            return state.listCauseSelect;
        },
        listChageWork(state) {
            return state.listChageWork;
        }

    },


    mutations: {
        updatelistSpec(state, payload) {
            state.listSpec = payload;
            state.listSpecSelect = [];
            for (let index = 0; index < payload.length; index++) {
                state.listSpecSelect.push(payload[index].nameSpec);

            }
        },
        updatelistUnits(state, payload) {
            state.listUnits = payload;
            state.listUnitsSelect = [];
            for (let index = 0; index < payload.length; index++) {
                state.listUnitsSelect.push(payload[index].nameUnits);

            }
        },

        updatelistTypeGoods(state, payload) {
            state.listTypeGoods = payload;
            state.listTypeGoodsSelect = [];
            for (let index = 0; index < payload.length; index++) {
                state.listTypeGoodsSelect.push(payload[index].name);

            }
        },

        updatelistGoods(state, payload) {
            state.listGoods = payload;
            state.listGoodsSelect = [];
            for (let index = 0; index < payload.length; index++) {
                state.listGoodsSelect.push(payload[index].nameGoods);

            }
        },
        updatelistProvider(state, payload) {
            state.listProvider = payload;
            state.listProviderSelect = [];
            for (let index = 0; index < payload.length; index++) {
                state.listProviderSelect.push(payload[index].nameProvider);

            }
        },
        updatelistCause(state, payload) {
            state.listCause = payload;
            state.listCauseSelect = [];
            for (let index = 0; index < payload.length; index++) {
                state.listCauseSelect.push(payload[index].nameCause);

            }
        },
        updateListChageWork(state, payload) {
            state.listChageWork = [];
            for (let index = 0; index < payload.length; index++) {
                state.listChageWork.push(payload[index].name_change);
                
            }
        }

    },


    actions: {
        newlistSpec({ commit }, payload) {
            commit("onLoad", true);
            Axios.post("/api/v1/hundbk/set/spec", {
                zn: payload
            })
                .then((res) => {
                    console.log(res);
                    commit("onLoad", false);
                    commit("updatelistSpec", res.data.message);

                })
                .catch(() => {
                    console.log("error");
                });
        },

        getlistSpec({ commit }) {
            commit("onLoad", true);
            Axios.post("/api/v1/hundbk/list/spec")
                .then((res) => {
                    console.log(res);
                    commit("onLoad", false);
                    commit("updatelistSpec", res.data.message);

                })
                .catch(() => {
                    console.log("error");
                });
        },

        newlistUnits({ commit }, payload) {
            commit("onLoad", true);
            Axios.post("/api/v1/hundbk/set/units", {
                zn: payload
            })
                .then((res) => {
                    console.log(res);
                    commit("onLoad", false);
                    commit("updatelistUnits", res.data.message);

                })
                .catch(() => {
                    console.log("error");
                });
        },

        getlistUnits({ commit }) {
            commit("onLoad", true);
            Axios.post("/api/v1/hundbk/list/units")
                .then((res) => {
                    console.log(res);
                    commit("onLoad", false);
                    commit("updatelistUnits", res.data.message);

                })
                .catch(() => {
                    console.log("error");
                });
        },
        newlistTypeGoods({ commit }, payload) {
            commit("onLoad", true);
            Axios.post("/api/v1/hundbk/set/tgoods", {
                zn: payload
            })
                .then((res) => {
                    console.log(res);
                    commit("onLoad", false);
                    commit("updatelistTypeGoods", res.data.message);

                })
                .catch(() => {
                    console.log("error");
                });
        },

        getlistTypeGoods({ commit }) {
            commit("onLoad", true);
            Axios.post("/api/v1/hundbk/list/tgoods")
                .then((res) => {
                    console.log(res);
                    commit("onLoad", false);
                    commit("updatelistTypeGoods", res.data.message);

                })
                .catch(() => {
                    console.log("error");
                });
        },

        newlistGoods({ commit }, payload) {
            commit("onLoad", true);
            Axios.post("/api/v1/hundbk/set/goods", {
                zn: payload
            })
                .then((res) => {
                    console.log(res);
                    commit("onLoad", false);
                    commit("updatelistGoods", res.data.message);

                })
                .catch(() => {
                    console.log("error");
                });
        },

        getlistGoods({ commit }) {
            commit("onLoad", true);
            Axios.post("/api/v1/hundbk/list/goods")
                .then((res) => {
                    console.log(res);
                    commit("onLoad", false);
                    commit("updatelistGoods", res.data.message);

                })
                .catch(() => {
                    console.log("error");
                });
        },

        dellistGoods({ commit }, payload) {
            commit("onLoad", true);
            Axios.post("/api/v1/hundbk/del/goods", {
                id: payload
            })
                .then((res) => {
                    console.log(res);
                    if (res.data.message.res == 1) {
                        commit("onLoad", false);
                        commit("updatelistGoods", res.data.message.mas);
                    } else {
                        alert('Данный товар был найден в расходе или приходе! Удалите его там после повторите попытку.')
                    }


                })
                .catch(() => {
                    console.log("error");
                });
        },

        newlistProvider({ commit }, payload) {
            commit("onLoad", true);
            Axios.post("/api/v1/hundbk/set/provider", {
                zn: payload
            })
                .then((res) => {
                    console.log(res);
                    commit("onLoad", false);
                    commit("updatelistProvider", res.data.message);

                })
                .catch(() => {
                    console.log("error");
                });
        },

        getlistProvider({ commit }) {
            commit("onLoad", true);
            Axios.post("/api/v1/hundbk/list/provider")
                .then((res) => {
                    console.log(res);
                    commit("onLoad", false);
                    commit("updatelistProvider", res.data.message);

                })
                .catch(() => {
                    console.log("error");
                });
        },
        newlistCause({ commit }, payload) {
            commit("onLoad", true);
            Axios.post("/api/v1/hundbk/set/cause", {
                zn: payload
            })
                .then((res) => {
                    console.log(res);
                    commit("onLoad", false);
                    commit("updatelistCause", res.data.message);

                })
                .catch(() => {
                    console.log("error");
                });
        },

        getlistCause({ commit }) {
            commit("onLoad", true);
            Axios.post("/api/v1/hundbk/list/cause")
                .then((res) => {
                    console.log(res);
                    commit("onLoad", false);
                    commit("updatelistCause", res.data.message);

                })
                .catch(() => {
                    console.log("error");
                });
        },
        getChageWorks({commit}){
            commit("onLoad", true);
            Axios.post("/api/v1/hundbk/list/chageWork")
                .then((res) => {
                    console.log(res);
                    commit("onLoad", false);
                    commit("updateListChageWork", res.data.message);

                })
                .catch(() => {
                    console.log("error");
                });
        }
    },
})