import Vue from 'vue'
import Vuex from 'vuex'
//import Axios from '../../plugins/axios'
import Axios from 'axios';

Vue.use(Vuex)

class User {
    constructor(id) {
      this.id = id;
    }
  }

export default ({
    state: {
        user: null,
        avt: false,
        ListMainSpec: [],
        load: false,
        listUsers: [],
        listUsersSelect: [],

    },
    getters: {
        getLoad(state) {
            return state.load;
        },
        listUsers(state){
            return state.listUsers;
        },
        listUsersSelect(state){
            return state.listUsersSelect;
        },
        getAuthClient(state) {
            return state.avt;
          },
    },


    mutations: {
        onLoad(state, payload) {
            state.load = payload;
        },
        updatelistUsers(state, payload){
            state.listUsers = payload;
            state.listUsersSelect = [];
            for (let index = 0; index < payload.length; index++) {
                state.listUsersSelect.push(payload[index].lname+' '+payload[index].fname+' '+payload[index].tname);

            }
        },
        setAuth(state, payload) {
            state.avt = payload;
          },
          setUserAuth(state, payload) {
            state.user = payload;
          },
    },


    actions: {
        newUser({ commit }, payload) {
            commit("onLoad", true);
            Axios.post("/api/v1/account/reg", {
                zn:payload
            })
                .then((res) => {
                    console.log(res);
                    commit("onLoad", false);
                    commit("updatelistUsers", res.data.message);

                })
                .catch(() => {
                    console.log("error");
                });
        },

        AuthPerson({commit}, payload){
            commit("onLoad", true);
            return new Promise((resolve, reject) => {
            Axios.post("/api/v1/account/login", {
                zn:payload
            })
                .then((res) => {
                    console.log(res);
                    if (res.data.message.message != "Успешно") {
                        alert("Не верный логин и пароль!");
                        commit("onLoad", false);
                        resolve(false);
                      } else {
                        commit("onLoad", false);
                        commit("setAuth", true);
                        commit("setUserAuth", new User(res.data.message.jwt));
                        Axios.defaults.headers.common["authorization"] = res.data.message.jwt;
                        localStorage.setItem("ecopack-user-token", res.data.message.jwt);
                        localStorage.setItem("ecopack-user-status", res.data.message.st);
                        localStorage.setItem("ecopack-user-fio", res.data.message.lnm+' '+res.data.message.fnm+' '+res.data.message.tnm);
                        localStorage.setItem("ecopack-user-eml", res.data.message.eml);
                        resolve(true);
                      }
                })
                .catch((res) => {
                    console.log(res);
                    commit("onLoad", false);
                    reject(false);
                });
            })
        },

        getExitClient({ commit }) {
            console.log("ex");
            localStorage.removeItem("ecopack-user-token");
            localStorage.removeItem("ecopack-user-status");
            localStorage.removeItem("ecopack-user-fio");
            localStorage.removeItem("ecopack-user-eml");
            commit("setUserAuth", null);
            commit("setAuth", false);
          },
      
        autoLoginUser({ commit }) {
            Axios.defaults.headers.common["authorization"] =
              localStorage.getItem("ecopack-user-token");
            commit(
              "setUserAuth",
              new User(localStorage.getItem("ecopack-user-token"))
            );
            commit("setAuth", true);
            commit("UpdateMenuList", localStorage.getItem("ecopack-user-status"));
          },

        getUserList({ commit }) {
            commit("onLoad", true);
            Axios.post("/api/v1/account/list/person")
                .then((res) => {
                    console.log(res);
                    commit("onLoad", false);
                    commit("updatelistUsers", res.data.message);

                })
                .catch(() => {
                    console.log("error");
                });
        },
        createLoginPass({ commit }, payload) {
            commit("onLoad", true);
            return new Promise((resolve, reject) => {
            Axios.post("/api/v1/account/person/genLogin", {
                id:payload
            })
                .then((res) => {
                    console.log(res);
                    commit("onLoad", false);
                    console.log(res.data.message);
                    resolve(res.data.message);
                })
                .catch(() => {
                    console.log("error");
                    reject([]);
                });
            })
        },

        blockUser({ commit }, payload) {
            commit("onLoad", true);
            Axios.post("/api/v1/account/person/block", {
                id: payload
            })
                .then((res) => {
                    console.log(res);
                    commit("onLoad", false);
                    commit("updatelistUsers", res.data.message);

                })
                .catch(() => {
                    console.log("error");
                });
        },
        getListPersonSpecActionInf({ commit }) {
            commit("onLoad", true);
            Axios.post("/api/v1/main/specialist/get")
                .then((res) => {
                    console.log('special');
                    console.log(res);
                    commit("onLoad", false);
                    commit("updateMainSpecialist", res.data.message);
                })
                .catch((rs) => {
                    console.log('special');
                    console.log(rs);
                });
        },
    },
})