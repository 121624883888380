<template>
    <v-container grid-list-xs>
        <v-dialog v-model="dialog" width="500">

            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    единицы измерения
                </v-card-title>

                <v-card-text>
                    <v-form ref="form" lazy-validation>
                        <v-text-field v-model="newUser.fname" label="название" required></v-text-field>
                    </v-form>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn color="success" class="mr-4" @click="SaveUser">
                        Сохранить
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="error" class="mr-4" @click="dialog = false">
                        Отменить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-row>
            <v-col cols="12" md="6">
                <h1>Единицы измерения</h1>
            </v-col>
            <v-col cols="12" md="6">
                <v-btn class="btn" @click="newData">Добавить</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>
                        Единицы измерения
                        <v-spacer></v-spacer>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Поиск" single-line
                            hide-details></v-text-field>
                    </v-card-title>
                    <v-data-table :headers="headers" :items="desserts" :search="search" @click:row="openData"></v-data-table>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>
  
<script>
// @ is an alias to /src


export default {
    name: 'Home',
    data: () => ({
        newUser: {
            id: 0,
            fname: '',
        },
        dialog: false,
        search: '',
        headers: [
          {
            text: 'id',
            align: 'start',
            value: 'id',
          },
          { text: 'название', value: 'nameUnits' },
        ],
        
    }),

    components: {

    },
    computed: {
        desserts() {
          return this.$store.getters.listUnits;
        },
    },
    methods: {
        newData(){
            this.newUser.id = 0;
            this.newUser.fname = '';
            this.dialog = true;
        },
        SaveUser() {
            this.$store.dispatch("newlistUnits", this.newUser);
            this.dialog = false;
        },
        openData(e){
            this.newUser.id = e.id;
            this.newUser.fname = e.nameUnits;
            this.dialog = true;
        }
    },
    mounted() {
        this.$store.dispatch("getlistUnits");
    }
}
</script>
  