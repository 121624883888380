import Vue from 'vue'
import Vuex from 'vuex'
import Main from "./module/main.js"
import hundbk from './module/hundbk.js'
import work from './module/work.js'
import report from './module/report.js'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

/* eslint-disable no-new */
const store = new Vuex.Store({
  plugins: [createPersistedState()],
  modules: {
    Main,
    hundbk,
    work,
    report,
  }
})

export default store
