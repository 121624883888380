<template>
    <v-container grid-list-xs>
        <v-dialog v-model="dialog" width="500">

            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    Товары
                </v-card-title>

                <v-card-text>
                    <v-form ref="form" lazy-validation>

                        <v-autocomplete v-model="dt.ggoods" :items="tpgoods" dense chips small-chips
                            label="Тип товара"></v-autocomplete>
                        <v-text-field v-model="dt.name" label="Название" required></v-text-field>

                        <v-autocomplete v-model="dt.ed" :items="edizm" dense chips small-chips
                            label="Ед. измерения"></v-autocomplete>
                        <v-switch v-model="dt.ras" label="многоразовый"></v-switch>
                        <v-text-field v-model="dt.num" label="учетный номер" required></v-text-field>
                        <v-text-field v-model="dt.col" label="минимальное кол-во для оповещения."
                            required></v-text-field>
                    </v-form>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn color="success" class="mr-4" @click="SaveUser">
                        Сохранить
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn v-if="dt.id != 0" color="error" class="mr-4" @click="delData">
                        Удалить
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="Warning" class="mr-4" @click="dialog = false">
                        Отменить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-row>
            <v-col cols="12" md="6">
                <h1>Товар</h1>
            </v-col>
            <v-col cols="12" md="6">
                <v-btn class="btn" @click="dialog = true">Добавить</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>
                        Товар
                        <v-spacer></v-spacer>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                            hide-details></v-text-field>
                    </v-card-title>
                    <v-data-table :headers="headers" :items="desserts" :search="search" @click:row="openData">
                        <template v-slot:item.typeGoods="{ item }">
                            <span v-if="item.typeGoods == 1">Инструмент</span>
                            <span v-else>Расходник</span>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
// @ is an alias to /src


export default {
    name: 'GroopsGoods',
    data: () => ({
        dt: {
            id: 0,
            name: '',
            ggoods: '',
            ed: '',
            ras: false,
            num: 0,
            col: 0.0,
        },
        dialog: false,
        search: '',
        headers: [
            {
                text: 'id',
                align: 'start',
                value: 'id',
            },
            { text: 'группа товаров', value: 'tgoods' },
            { text: 'название', value: 'nameGoods' },
            { text: 'номер', value: 'numberUc' },
            { text: 'ед. изм', value: 'nameUnits' },
            { text: 'тип товара', value: 'typeGoods' },
            { text: 'кол для оповещения', value: 'minCol' },
        ],
    }),

    components: {

    },
    computed: {
        desserts() {
            return this.$store.getters.listGoods;
        },
        tpgoods() {
            return this.$store.getters.listTypeGoodsSelect;
        },
        edizm() {
            return this.$store.getters.listUnitsSelect;
        }

    },
    methods: {
        newData() {
            this.dt.id = 0;
            this.dt.name = '';
            this.dt.ggoods = '';
            this.dt.ed = '';
            this.dt.ras = false;
            this.dt.num = 0;
            this.dt.col = 0.0;
            this.dialog = true;
        },
        SaveUser() {
            if (this.dt.name != '') {
                this.$store.dispatch("newlistGoods", this.dt);
                this.dialog = false;
            } else {
                alert("Название товара не может быть пустым! Если не обходимо поментить что товар не используется допишите в скобках удален!")
            }

        },
        delData() {
            let result = confirm('Вы уверены что хотите удалить?');
            if (result) {
                this.$store.dispatch("dellistGoods", this.dt.id);
                this.dialog = false;
            }

        },
        openData(e) {

            this.dt.id = e.id;
            this.dt.name = e.nameGoods;
            this.dt.ggoods = e.tgoods;
            this.dt.ed = e.nameUnits;
            this.dt.ras = e.typeGoods;
            this.dt.num = e.numberUc;
            this.dt.col = e.minCol;
            this.dialog = true;
        }
    },
    mounted() {
        this.$store.dispatch("getlistGoods");
        this.$store.dispatch("getlistTypeGoods");
        this.$store.dispatch("getlistUnits");
    }
}
</script>