<template>
    <v-container grid-list-xs>
        <v-dialog v-model="dialog" width="900">

            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    заявки
                </v-card-title>

                <v-card-text>
                    <v-form ref="form" lazy-validation>
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field v-model="dt.info" label="описание куда необходимое если нет в выборе"
                                    required></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-autocomplete v-model="goods.name" :items="gds" chips small-chips
                                    label="Товар"></v-autocomplete>
                                <v-text-field v-model="goods.newGood" v-if="goods.name == 'новый'"
                                    label="напишите примерное название" required></v-text-field>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-text-field v-model="goods.col" label="Кол-во" required></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-autocomplete v-model="goods.cause" :items="cause" chips small-chips
                                    label="куда"></v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-btn @click="addData">+</v-btn>
                            </v-col>
                            <v-col cols="12" md="12">
                                <v-data-table :headers="headers_goods" :items="dt.list">
                                    <template v-slot:item.act="{ item }">
                                        <v-btn @click="delData(item)" :key="item.name">-</v-btn>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>



                    </v-form>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn color="success" class="mr-4" @click="SaveUser">
                        Сохранить
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="error" class="mr-4" @click="dialog = false">
                        Отменить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-row>
            <v-col cols="12" md="6">
                <h1>Заявки на товар</h1>
            </v-col>
            <v-col cols="12" md="6">
                <v-btn class="btn" @click="newData">Добавить</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>
                        Заявки на товар
                        <v-spacer></v-spacer>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="поиск" single-line
                            hide-details></v-text-field>
                    </v-card-title>
                    <v-data-table :headers="headers" :items="desserts" :search="search"
                        @click:row="openData"></v-data-table>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
// @ is an alias to /src


export default {
    name: 'GroopsGoods',
    data: () => ({
        dt: {
            id: 0,
            pers: '',
            spec: '',
            info: '',
            list: [],
        },
        goods: {
            name: '',
            newGood: '',
            col: 0,
            cause: 'общие расходы',
        },
        dialog: false,
        search: '',
        headers: [
            {
                text: 'id',
                align: 'start',
                value: 'id',
            },
            { text: 'ФИО', value: 'pers' },
            { text: 'специальность', value: 'nameSpec' },
            { text: 'товар', value: 'ccc' },            
            { text: 'дата', value: 'dtv' },
            { text: 'описание', value: 'info' },
            { text: 'статус выполнения', value: 'doneBid' },
        ],
        headers_goods: [
            { text: 'товар', value: 'name' },
            { text: 'новый', value: 'newGood' },
            { text: 'кол-во', value: 'col' },
            { text: 'куда', value: 'cause' },
            { text: 'действие', value: 'act' },
        ]

    }),

    components: {

    },
    computed: {
        desserts() {
            return this.$store.getters.listBid;
        },
        gds() {
            return this.$store.getters.listGoodsSelect;
        },
        cause() {
            return this.$store.getters.listCauseSelect;
        },
        person() {
            return this.$store.getters.listUsersSelect;
        },
        spec() {
            return this.$store.getters.listSpecSelect;
        }
    },
    methods: {
        newData() {
            this.dt.id = 0;
            this.dt.pers = '';
            this.dt.spec = '';
            this.dt.list = [];
            this.dt.info = '';
            this.goods.name = '';
            this.goods.newGood = '';
            this.goods.col = 0;
            this.goods.cause = 'общие расходы';
            this.dialog = true;
        },
        addData() {
            this.dt.list.push(JSON.parse(JSON.stringify(this.goods)))
            this.goods.name = '';
            this.goods.newGood = '';
            this.goods.col = 0;
            this.goods.cause = 'общие расходы';
        },
        delData(e) {

            for (let i = 0; i < this.dt.list.length; i++) {
                if (this.dt.list[i].name == e.name) {
                    this.dt.list.splice(i, 1);
                    break;
                }
            }
            console.log(e.goods);
        },
        SaveUser() {
            this.$store.dispatch("newlistBid", this.dt);
            this.dialog = false;
        },
        openData(e) {
            this.dt.id = e.id;
            this.dt.pers = e.pers;
            this.dt.spec = e.nameSpec;
            this.dt.list = e.list;
            this.dt.info = e.inf;
            this.dialog = true;
        }
    },
    mounted() {
        this.$store.dispatch("getlistBid");
        this.$store.dispatch("getlistGoods");
        this.$store.dispatch("getlistCause");
        this.$store.dispatch("getUserList");
        this.$store.dispatch("getlistSpec");
    }
}
</script>