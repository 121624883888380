<template>
    <v-container grid-list-xs>
        <v-dialog v-model="dialog" width="500">

            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    Пользователь
                </v-card-title>

                <v-card-text>
                    <v-form ref="form" lazy-validation>
                        <v-text-field v-model="newUser.lname" label="Фамилия" required></v-text-field>
                        <v-text-field v-model="newUser.fname" label="Имя" required></v-text-field>
                        <v-text-field v-model="newUser.tname" label="Отчество" required></v-text-field>
                        <v-textarea label="Дополнительная информация о пользователе" v-model="newUser.txt"></v-textarea>
                        <v-autocomplete v-model="newUser.nach" :items="users" dense chips small-chips
                            label="Начальник"></v-autocomplete>
                            <v-autocomplete v-model="newUser.smen" :items="chageWork" dense chips small-chips
                            label="смена"></v-autocomplete>
                    </v-form>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn color="success" class="mr-4" @click="SaveUser">
                        Сохранить
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn v-if="newUser.id!=0" color="error" class="mr-4" @click="genPass">
                        пароль
                    </v-btn>
                    <v-btn v-if="newUser.id!=0" color="error" class="mr-4" @click="blockUser">
                        <span>блок</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="error" class="mr-4" @click="dialog = false">
                        Отменить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-row>
            <v-col cols="12" md="6">
                <h1>регистрация</h1>
            </v-col>
            <v-col cols="12" md="6">
                <v-btn class="btn" @click="newPerson">Добавить</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>
                        Работники
                        <v-spacer></v-spacer>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                            hide-details></v-text-field>
                    </v-card-title>
                    <v-data-table :headers="headers" :items="desserts" :search="search" @click:row="openDialog">
                        <template v-slot:item.block="{ item }">
                            <v-icon v-if="item.block==1">mdi-account-lock</v-icon>
                            <v-icon v-else>mdi-account-lock-open</v-icon>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>
  
<script>
// @ is an alias to /src


export default {
    name: 'Home',
    data: () => ({
        newUser: {
            id: 0,
            fname: '',
            lname: '',
            tname: '',
            txt: '',
            nach: '',
            smen: '',
        },
        dialog: false,
        search: '',
        headers: [
            {
                text: 'id',
                align: 'start',
                value: 'id',
            },
            { text: 'Фамилия', value: 'lname' },
            { text: 'Имя', value: 'fname' },
            { text: 'Отчество', value: 'tname' },
            { text: 'Логин', value: 'login' },
            { text: 'Информация', value: 'info' },
            { text: 'Отв лиц', value: 'nach' },
            { text: 'Блок', value: 'block' },
        ],

    }),

    components: {

    },
    computed: {
        desserts() {
            return this.$store.getters.listUsers;
        },
        users() {
            return this.$store.getters.listUsersSelect;
        },
        chageWork() {
            return this.$store.getters.listChageWork;
        }
    },
    methods: {
        newPerson(){
            this.newUser.id = 0;
            this.newUser.fname = '';
            this.newUser.lname= '';
            this.newUser.tname= '';
            this.newUser.txt= '';
            this.dialog = true;
        },
        SaveUser() {
            this.$store.dispatch("newUser", this.newUser);
            this.dialog = false;
        },
        openDialog(e){
            this.newUser.id = e.id;
            this.newUser.fname = e.fname;
            this.newUser.lname= e.lname
            this.newUser.tname= e.tname
            this.newUser.txt= e.info
            this.dialog = true;
        },
        genPass(){
            this.$store.dispatch("createLoginPass", this.newUser.id).then(res=>{
                if(res!=[]){
                    alert("Доступ к системе создан! Логин:"+res['login']+" pass: "+res['password']);
                } else {
                    alert("Создание доступа не возможно!")
                }
                this.dialog = false;
            });
        },
        blockUser(){
            this.$store.dispatch("blockUser", this.newUser.id)
            this.dialog = false;
        }

    },
        mounted() {
        this.$store.dispatch("getUserList");
        this.$store.dispatch("getChageWorks");
    },
}
</script>
  