import Vue from 'vue'
import Axios from 'axios';
import App from './App.vue'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify'
import excel from 'vue-excel-export'

Vue.config.productionTip = false
Vue.use(excel)

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App),
  created () {
    const token = localStorage.getItem('ecopack-user-token')
    //Axios.defaults.baseURL = 'http://scklad.local/';
    Axios.defaults.baseURL = 'https://server.globalecopack.su/';
    //Axios.defaults.baseURL = 'https://server.b-hire.ru/';
     if (token) {
       this.$store.dispatch('autoLoginUser', token)
     } 
  }
}).$mount('#app')
