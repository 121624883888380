<template>
    <v-container grid-list-xs>
        <v-dialog v-model="dialog" width="900">

            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    приход
                </v-card-title>

                <v-card-text>
                    <v-form ref="form" lazy-validation>
                        <v-row>

                            <v-col cols="12">
                                <v-text-field type="date" v-model="dt.date" label="дата прихода" required></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field v-model="dt.numPrih" label="номер прихода" required></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field v-model="dt.schet" label="Счет" required></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-autocomplete v-model="dt.provider" :items="edizm" dense chips small-chips
                                    label="Поставщик"></v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-autocomplete v-model="listgg.goods" :items="tpgoods" dense chips 
                                    label="товар"></v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-text-field v-model="listgg.col" label="кол-во" required></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-autocomplete v-model="listgg.cause" :items="cause" dense chips 
                                    label="для чего"></v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="1">
                                <v-btn @click="addData">+</v-btn>
                            </v-col>
                            <v-col cols="12" md="12" v-if="listgg.goods=='новый'">
                                <add-goods></add-goods>
                            </v-col>
                            <v-col cols="12">
                                <v-data-table :headers="headers_goods" :items="dt.list"
                       >
                       <template v-slot:item.act="{ item }">
                        <v-btn @click="delData(item)" :key="item.goods">-</v-btn>
                        </template>
                    </v-data-table>
                            </v-col>
                        </v-row>
                        

                    </v-form>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn color="success" class="mr-4" @click="SaveData">
                        Сохранить
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="error" class="mr-4" @click="dialog = false">
                        Отменить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-row>
            <v-col cols="12" md="6">
                <h1>Приход</h1>
            </v-col>
            <v-col cols="12" md="6">
                <v-btn class="btn" @click="newData">Добавить</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>
                        Список
                        <v-spacer></v-spacer>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Поиск" single-line
                            hide-details></v-text-field>
                    </v-card-title>
                    <v-data-table :headers="headers" :items="desserts" :search="search"
                        @click:row="openData"></v-data-table>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>
  
<script>
// @ is an alias to /src

import AddGoods from '../../components/goods/AddGoods.vue';
export default {
    name: 'Arrival',
    data: () => ({
        dt: {
            id: 0,
            date: new Date().toISOString().substr(0, 10),
            numPrih: 0,
            schet: 0,
            col: 0,
            list: [
                
            ]
        },
        listgg: {
            goods: '',
            col: 0,
            cause: 'общие расходы',
        },
        dialog: false,
        search: '',
        headers: [
            {
                text: 'id',
                align: 'start',
                value: 'id',
            },
            { text: 'номер прихода', value: 'nameArrival' },
            { text: 'счет', value: 'numerSchet' },
            { text: 'товар', value: 'ccc' },
            { text: 'кол-во', value: 'count' },
            { text: 'дата', value: 'dtv' },
            { text: 'поставщик', value: 'nameProvider' },
            { text: 'куда', value: 'nameCause' },

        ],
        headers_goods: [
            { text: 'товар', value: 'goods' },
            { text: 'кол-во', value: 'col' },
            { text: 'куда', value: 'cause' },
            { text: 'действие', value: 'act' },
        ]
    }),

    components: {
        AddGoods
    },
    computed: {
        desserts() {
            return this.$store.getters.listArrival;
        },
        tpgoods() {
            return this.$store.getters.listGoodsSelect;
        },
        edizm() {
            return this.$store.getters.listProviderSelect;
        },
        cause() {
            return this.$store.getters.listCauseSelect;
        }

    },
    methods: {
        newData() {
            this.dt.id = 0;
            this.dt.date = new Date().toISOString().substr(0, 10);
            this.dt.numPrih = 0;
            this.dt.schet=0;
            this.listgg.goods = '';
            this.listgg.col = 0;
            this.dt.provider = '';
            this.listgg.cause = 'общие расходы';
            this.dt.list = [];
            this.dialog = true;
        },
        addData(){
            this.dt.list.push(JSON.parse(JSON.stringify(this.listgg)));
            this.listgg.goods = '';
            this.listgg.cause = 'общие расходы';
            this.listgg.col = 0;
            console.log(this.dt.list)
        },
        delData(e){
            console.log(e);
            for(let i=0; i< this.dt.list.length; i++){
                if(this.dt.list[i].goods == e.goods){
                    this.dt.list.splice(i,1);
                    break;
                }
            }
            console.log(e.goods);
        },
        SaveData() {
            console.log(this.dt);
            this.$store.dispatch("newlistArrival", this.dt);
            this.dialog = false;
        },
        openData(e) {
            this.dt.id = e.id;
            this.dt.date = e.createData;
            this.dt.numPrih = e.nameArrival;
            this.dt.schet = e.numerSchet;
            this.dt.list = e.list;
            
            this.dt.provider = e.nameProvider;
            
            this.dialog = true;
        }
    },
    mounted() {
        this.$store.dispatch("getlistGoods");
        this.$store.dispatch("getlistProvider");
        this.$store.dispatch("getlistArrival");
        this.$store.dispatch("getlistCause");
    }
}
</script>
  