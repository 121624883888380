<template>
    <v-container fluid>

        <v-row>
            <v-col cols="12" md="6">
                <h1>остатки</h1>
            </v-col>
            <v-col cols="12" md="6">
                <export-excel class="btn" :data="desserts">
                    Выгрузить
                </export-excel>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>
                        Оборотно-сальдовая ведомость
                        <v-spacer></v-spacer>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Поиск" single-line
                            hide-details></v-text-field>
                    </v-card-title>
                    <v-data-table :headers="headers" :items="desserts" :search="search" @click:row="openData">
                        <template v-slot:item.act="{ item }">
                            <span>{{ item.dd - item.rr }}</span>

                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
// @ is an alias to /src


export default {
    name: 'Reverse',
    data: () => ({

        newUser: {
            id: 0,
            fname: '',
        },

        dialog: false,
        search: '',
        headers: [
            { text: 'Дата составления', value: 'createData' },
            { text: 'Поставщик', value: 'nameProvider' },
            { text: '№накладной', value: 'nameArrival' },
            { text: '№счет-фактуры', value: 'numerSchet' },
            { text: 'тип', value: 'tp' },
            { text: '№п/п', value: 'nn' },
            { text: 'Наименование товара', value: 'nameGoods' },
            { text: 'Ост.на начало', value: 'nameGoods' },
            { text: 'Ед.изм.', value: 'nameUnits' },
            { text: 'Приход', value: 'count' },
            { text: 'Примечание', value: 'nameCause' },

        ],

    }),

    components: {

    },
    computed: {
        desserts() {
            return this.$store.getters.listBalanceReverse;
            //https://www.npmjs.com/package/vue-json-csv
        },
    },
    methods: {


        openData(e) {
            this.newUser.id = e.id;
            this.newUser.fname = e.nameUnits;
            this.dialog = true;
        }
    },
    mounted() {
        this.$store.dispatch("getlistBalanceReverse");
    }
}
</script>