<template>
    <v-container fluid>

        <v-row>
            <v-col cols="12">
                <h1>Табель</h1>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="2" v-for="(n,i) in chageWork" :key="i"> 
                <v-btn color="primary" @click="onOpenList(n)">{{ n }}</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>
                        Учет времени
                        <v-spacer></v-spacer>
                    </v-card-title>
                    <v-data-table :headers="headers" :items="desserts">
                        <template v-slot:item.n1="{ item }">
                            <v-autocomplete :items="listItems" dense chips small-chips
                                :key="item.id + item.n1" :value="item.n1" @change="newStatus($event, 1, item.id)"></v-autocomplete>
                        </template>
                        <template v-slot:item.n2="{ item }">
                            <v-autocomplete v-if="todayDay >= 2" :items="listItems" dense chips small-chips
                                :key="item.id + item.n2" :value="item.n2" @change="newStatus($event, 2, item.id)"></v-autocomplete>
                        </template>
                        <template v-slot:item.n3="{ item }">
                            <v-autocomplete v-if="todayDay >= 3" :items="listItems" dense chips small-chips
                                :key="item.id + item.n3" :value="item.n3" @change="newStatus($event, 3, item.id)"></v-autocomplete>
                        </template>
                        <template v-slot:item.n4="{ item }">
                            <v-autocomplete v-if="todayDay >= 4" :items="listItems" dense chips small-chips
                                :key="item.id + item.n4" :value="item.n4" @change="newStatus($event, 4, item.id)"></v-autocomplete>
                        </template>
                        <template v-slot:item.n5="{ item }">
                            <v-autocomplete v-if="todayDay >= 5" :items="listItems" dense chips small-chips
                                :key="item.id + item.n5" :value="item.n5" @change="newStatus($event, 5, item.id)"></v-autocomplete>
                        </template>
                        <template v-slot:item.n6="{ item }">
                            <v-autocomplete v-if="todayDay >= 6" :items="listItems" dense chips small-chips
                                :key="item.id + item.n6" :value="item.n6" @change="newStatus($event, 6, item.id)"></v-autocomplete>
                        </template>
                        <template v-slot:item.n7="{ item }">
                            <v-autocomplete v-if="todayDay >= 7" :items="listItems" dense chips small-chips
                                :key="item.id + item.n7" :value="item.n7" @change="newStatus($event, 7, item.id)"></v-autocomplete>
                        </template>
                        <template v-slot:item.n8="{ item }">
                            <v-autocomplete v-if="todayDay >= 8" :items="listItems" dense chips small-chips
                                :key="item.id + item.n8" :value="item.n8" @change="newStatus($event, 8, item.id)"></v-autocomplete>
                        </template>
                        <template v-slot:item.n9="{ item }">
                            <v-autocomplete v-if="todayDay >= 9" :items="listItems" dense chips small-chips
                                :key="item.id + item.n9" :value="item.n9" @change="newStatus($event, 9, item.id)"></v-autocomplete>
                        </template>
                        <template v-slot:item.n10="{ item }">
                            <v-autocomplete v-if="todayDay >= 10" :items="listItems" dense chips small-chips
                                :key="item.id + item.n10" :value="item.n10" @change="newStatus($event, 10, item.id)"></v-autocomplete>
                        </template>
                        <template v-slot:item.n11="{ item }">
                            <v-autocomplete v-if="todayDay >= 11" :items="listItems" dense chips small-chips
                                :key="item.id + item.n11" :value="item.n11" @change="newStatus($event, 11, item.id)"></v-autocomplete>
                        </template>
                        <template v-slot:item.n12="{ item }">
                            <v-autocomplete v-if="todayDay >= 12" :items="listItems" dense chips small-chips
                                :key="item.id + item.n12" :value="item.n12" @change="newStatus($event, 12, item.id)"></v-autocomplete>
                        </template>
                        <template v-slot:item.n13="{ item }">
                            <v-autocomplete v-if="todayDay >= 13" :items="listItems" dense chips small-chips
                                :key="item.id + item.n13" :value="item.n13" @change="newStatus($event, 13, item.id)"></v-autocomplete>
                        </template>
                        <template v-slot:item.n14="{ item }">
                            <v-autocomplete v-if="todayDay >= 14" :items="listItems" dense chips small-chips
                                :key="item.id + item.n14" :value="item.n14" @change="newStatus($event, 14, item.id)"></v-autocomplete>
                        </template>
                        <template v-slot:item.n15="{ item }">
                            <v-autocomplete v-if="todayDay >= 15" :items="listItems" dense chips small-chips
                                :key="item.id + item.n15" :value="item.n15" @change="newStatus($event, 15, item.id)"></v-autocomplete>
                        </template>

                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
// @ is an alias to /src


export default {
    name: 'TimeSheet',
    data: () => ({
        dt: {
            id: 0,
            pers: '',
            spec: '',
            info: '',
            list: [],
        },



    }),

    components: {

    },
    computed: {
        desserts() {
            return this.$store.getters.listSpecSlave;
        },
        listItems() {
            return this.$store.getters.listWorkTimeStatus;
        },
        todayDay() {
            let dt = new Date();
            let t = dt.getDate();
            console.log(t);
           return t;
        },
        chageWork() {
            return this.$store.getters.listChageWork;
        },
        headers() {
            let date = new Date();
            let tt = date.getMonth()+1;
            if(tt < 10) {
                tt = '0'+tt;
            }
            if (date.getDate() <= 15) {
                return [
                    {
                        text: 'id',
                        align: 'start',
                        value: 'id',
                    },
                    { text: 'ФИО', value: 'FIO' },
                    { text: '01.' + tt, value: 'n1' },
                    { text: '02.' + tt, value: 'n2' },
                    { text: '03.' + tt, value: 'n3' },
                    { text: '04.' + tt, value: 'n4' },
                    { text: '05.' + tt, value: 'n5' },
                    { text: '06.' + tt, value: 'n6' },
                    { text: '07.' + tt, value: 'n7' },
                    { text: '08.' + tt, value: 'n8' },
                    { text: '09.' + tt, value: 'n9' },
                    { text: '10.' + tt, value: 'n10' },
                    { text: '11.' + tt, value: 'n11' },
                    { text: '12.' + tt, value: 'n12' },
                    { text: '13.' + tt, value: 'n13' },
                    { text: '14.' + tt, value: 'n14' },
                    { text: '15.' + tt, value: 'n15' },
                ]
            } else {
                Date.prototype.daysInMonth = function () {
                    return 33 - new Date(this.getFullYear(), this.getMonth(), 33).getDate();
                };
                let dd = new Date().daysInMonth();
                let yy = [
                    {
                        text: 'id',
                        align: 'start',
                        value: 'id',
                    },
                    { text: 'ФИО', value: 'FIO' },
                ]
                for (let index = 16; index <= dd; index++) {
                    yy.push({ text: index+'.' + tt, value: 'n'+(index-15) });
                    
                }
                return yy;
            }
        }

    },
    methods: {
        newStatus(e, b, n) {
            let date = new Date();
            let r = date.getDate();
            let tt = date.getMonth()+1;
            if(tt < 10) {
                tt = '0'+tt;
            }
            let ss = '';
            if (r > 15){
                ss = date.getFullYear()+'-'+tt+'-'+(b+15);
            } else {
                ss = date.getFullYear()+'-'+tt+'-'+b;
            }
            let t = {
                zn: e,
                num: ss,
                id: n,
            }
            console.log(t);
            this.$store.dispatch("setlistSpecSlave",t);
        },
        onOpenList(n){
            this.$store.dispatch("getlistSpecSlave",n);
        },
        
        
    },
    mounted() {
        this.$store.dispatch("getlistSpecSlave", 1);
        this.$store.dispatch("getWorkTimeStatus");
        this.$store.dispatch("getChageWorks");
    }
}
</script>