<template>
    <v-container grid-list-xs>
        <v-dialog v-model="dialog" width="500">

            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    возрат инструмента
                </v-card-title>

                <v-card-text>
                    <v-form ref="form" lazy-validation>
                        <v-autocomplete v-model="dt.g1" :items="[]" dense chips small-chips
                            label="Товар"></v-autocomplete>
                        <v-text-field v-model="dt.g2" label="Кол-во" required></v-text-field>

                        <v-autocomplete v-model="dt.g3" :items="[]" dense chips small-chips
                            label="Работник"></v-autocomplete>
                            <v-autocomplete v-model="dt.g4" :items="[]" dense chips small-chips
                            label="специальность"></v-autocomplete>
                       
                    </v-form>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn color="success" class="mr-4" @click="SaveUser">
                        Сохранить
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="error" class="mr-4" @click="dialog = false">
                        Отменить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-row>
            <v-col cols="12" md="6">
                <h1>Расход товаров</h1>
            </v-col>
            <v-col cols="12" md="6">
                <v-btn class="btn" @click="dialog = true">Добавить</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>
                        Расход товаров
                        <v-spacer></v-spacer>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                            hide-details></v-text-field>
                    </v-card-title>
                    <v-data-table :headers="headers" :items="desserts" :search="search"></v-data-table>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>
  
<script>
// @ is an alias to /src


export default {
    name: 'GroopsGoods',
    data: () => ({
        dt: {
            id: 0,
            g1: '',
            g2:'',

            g3:'',

            g4:'',
        },
        dialog: false,
        search: '',
        headers: [
          {
            text: 'id',
            align: 'start',
            value: 'id',
          },
          { text: 'название', value: 'name' },
          { text: 'описание', value: 'comments' },
        ],
        
    }),

    components: {

    },
    computed: {
        desserts() {
          return this.$store.getters.listTypeGoods;
        },
    },
    methods: {
        SaveUser() {
            this.$store.dispatch("newlistTypeGoods", this.newUser);
        },
    },
    mounted() {
        this.$store.dispatch("getlistTypeGoods");
    }
}
</script>
  