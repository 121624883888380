import Vue from 'vue'
import Vuex from 'vuex'
//import Axios from '../../plugins/axios'
import Axios from 'axios';

Vue.use(Vuex)

export default ({
    state: {
        listArrival: [],
        listConsumption: [],
        listBid: [],
        listSpecSlave: [],
        listWorkTimeStatus: [],
        listProductIngener: [],

    },
    getters: {
        listArrival(state) {
            return state.listArrival
        },
        listProductIngener(state) {
            return state.listProductIngener;
        },
        listConsumption(state) {
            return state.listConsumption
        },
        listBid(state) {
            return state.listBid;
        },
        listSpecSlave(state) {
            return state.listSpecSlave;
        },
        listWorkTimeStatus(state){
            return state.listWorkTimeStatus;
        },


    },


    mutations: {
        updatelistArrival(state, payload) {
            state.listArrival = payload;

        },
        updatelistConsumption(state, payload) {
            state.listConsumption = payload;

        },
        updatelistBid(state, payload) {
            state.listBid = payload;

        },

        updateListSpecSlave(state, payload)
        {
            state.listSpecSlave = payload;
        },
        updateListTimeStatus(state, payload){
            state.listWorkTimeStatus = [];
            for (let index = 0; index < payload.length; index++) {
                state.listWorkTimeStatus.push(payload[index].name);
                
            }
        },
        updateListProductIngener(state, payload){
            state.listProductIngener = payload
        }

    },


    actions: {
        newlistArrival({ commit }, payload) {
            commit("onLoad", true);
            Axios.post("/api/v1/work/set/arrival", {
                zn: payload
            })
                .then((res) => {
                    console.log(res);
                    commit("onLoad", false);
                    commit("updatelistArrival", res.data.message);

                })
                .catch(() => {
                    console.log("error");
                });
        },

        getlistArrival({ commit }) {
            commit("onLoad", true);
            Axios.post("/api/v1/work/get/arrival")
                .then((res) => {
                    console.log(res);
                    commit("onLoad", false);
                    commit("updatelistArrival", res.data.message);

                })
                .catch(() => {
                    console.log("error");
                });
        },

        newlistConsumption({ commit }, payload) {
            commit("onLoad", true);
            Axios.post("/api/v1/work/set/consumption", {
                zn: payload
            })
                .then((res) => {
                    console.log(res);
                    commit("onLoad", false);
                    commit("updatelistConsumption", res.data.message);

                })
                .catch(() => {
                    console.log("error");
                });
        },

        getlistConsumption({ commit }) {
            commit("onLoad", true);
            Axios.post("/api/v1/work/get/consumption")
                .then((res) => {
                    console.log(res);
                    commit("onLoad", false);
                    commit("updatelistConsumption", res.data.message);

                })
                .catch(() => {
                    console.log("error");
                });
        },

        DeletelistConsumption({ commit }, payload) {
            commit("onLoad", true);
            Axios.post("/api/v1/work/del/consumption", {
                id:payload
            })
                .then((res) => {
                    console.log(res);
                    commit("onLoad", false);
                    commit("updatelistConsumption", res.data.message);

                })
                .catch(() => {
                    console.log("error");
                });
        },

        newlistBid({ commit }, payload) {
            commit("onLoad", true);
            Axios.post("/api/v1/work/set/bid", {
                zn: payload
            })
                .then((res) => {
                    console.log(res);
                    commit("onLoad", false);
                    commit("updatelistBid", res.data.message);

                })
                .catch(() => {
                    console.log("error");
                });
        },

        getlistBid({ commit }) {
            commit("onLoad", true);
            Axios.post("/api/v1/work/get/bid")
                .then((res) => {
                    console.log(res);
                    commit("onLoad", false);
                    commit("updatelistBid", res.data.message);

                })
                .catch(() => {
                    console.log("error");
                });
        },

        getlistSpecSlave({ commit }, payload) {
            commit("onLoad", true);
            Axios.post("/api/v1/work/get/spec/slave", {
                zn:payload
            })
                .then((res) => {
                    console.log('slave');
                    console.log(res);
                    commit("onLoad", false);
                    commit("updateListSpecSlave", res.data.message);

                })
                .catch(() => {
                    console.log("error");
                });
        },
        getWorkTimeStatus({commit}){
            commit("onLoad", true);
            Axios.post("/api/v1/work/get/spec/time/status")
                .then((res) => {
                    console.log('slave');
                    console.log(res);
                    commit("onLoad", false);
                    commit("updateListTimeStatus", res.data.message);

                })
                .catch(() => {
                    console.log("error");
                });
        },
        setlistSpecSlave({commit}, payload){
            commit("onLoad", true);
            Axios.post("/api/v1/work/set/spec/slave", {
                zn: payload
            })
                .then((res) => {
                    console.log('slave');
                    console.log(res);
                    commit("onLoad", false);
                    commit("updateListSpecSlave", res.data.message);

                })
                .catch(() => {
                    console.log("error");
                });
        },
        newProductUser({commit}, payload){
            commit("onLoad", true);
            Axios.post("/api/v1/work/set/ingener/product", {
                zn: payload
            })
                .then((res) => {
                    console.log('product');
                    console.log(res);
                    commit("onLoad", false);
                    commit("updateListProductIngener", res.data.message);

                })
                .catch(() => {
                    console.log("error");
                });
        },

        getProductUser({commit}){
            commit("onLoad", true);
            Axios.post("/api/v1/work/get/ingener/product")
                .then((res) => {
                    console.log('product');
                    console.log(res);
                    commit("onLoad", false);
                    commit("updateListProductIngener", res.data.message);

                })
                .catch(() => {
                    console.log("error");
                });
        },

    },
})